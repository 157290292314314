@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Book.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Hairline.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Heavy.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Markpro';
  src: url('../../public/font-family/MarkPro-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

body {
  background-color: #f7f8fa;
  font-family: 'Markpro', sans-serif;
}

:root {
  --body: #f7f8fa;
  --gray: #7A7777;
  --red: #f05127;
}

.container {
  margin: auto;
  max-width: 1280px;
}

@media screen and (max-width: 1320px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Hamburger menu */
.lbl-resp {
  /* position: relative;
  float: right; */
  cursor: pointer;
  display: none;
}

.inp_check {
  display: none;
}

@media screen and (max-width: 1024px) {
  .lbl-resp {
    display: block;
  }

  .rsp-header {
    margin-top: 100px;
  }

  .menu {
    margin-top: 0px;
    padding: 0px;
    /* width: 50px; */
  }

  .menu label {
    right: 30px;
  }

  .menu ul {
    position: fixed;
    transition: 1s;
    right: -100%;
    top: 62px;
    width: 225px;
    flex-direction: column;
    background-color: #f7f8fa;
    height: 100vh;
    z-index: 990;
    /* overflow-y: auto; */
  }

  .menu ul li {
    display: block;
    width: 100%;
    padding: 0 20px;
  }

  .menu ul li a {
    width: 100%;
  }

  #onclick:checked~ul {
    right: 0px;
  }

  #onclick:checked~body {
    overflow: hidden;
  }

  .navlist {
    padding-top: 20px;
  }
}

/* Logo Slider */
.slider {
  width: 100%;
  overflow: hidden;
}

.block {
  width: 100%;
}

input:focus {
  outline: none;
}

.active {
  color: #030303;
}



/* Kataloq Slider */


/* .product-list-wrapper {
  position: fixed;
  top: 190px;
  left: -100%; 
  width: 100%;
  max-width: 300px;
  height: 100%;
  transition: left 0.7s ease-in-out;
  z-index: 800;
  border-radius: 10px;

}

.product-list-wrapper.visible {
  left: 0;
  
}

@media (min-width: 1050px) {
  .product-list-wrapper {
    display: block;
    position: sticky;
    top: 0;
  }
} */


.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  /* Arka plan rengini ihtiyacınıza göre değiştirebilirsiniz */
  z-index: 990;
  /* Üstte görünmesi için yüksek bir z-index değeri */
  overflow-y: auto;
  /* İçerik taşması durumunda kaydırma sağlar */
}



/* Main container styling */
.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image {
  width: 100%;
  height: auto;
  background-color: #D9D9D9;
  cursor: pointer;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 32px;
}

.thumbnail {
  width: 160px;
  height: 160px;
  background-color: #D9D9D9;
  border-radius: 12px;
  cursor: pointer;
}

/* Popup overlay styling */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

/* Popup content styling */
.popup-content {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
}

.close-button {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #f56565;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}



.embed_header_video iframe {
  border-radius: 12px;
  width: 100% !important;
  height: 100% !important;
}




/* LOADER STYLES */
.loader {
  display: block;
  position: absolute;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--red);
  width: 36px;
  height: 36px;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* Custom scrollbar gizleme stili */
.scrollbar-hidden {
  scrollbar-width: none;
  /* Firefox için */
  -ms-overflow-style: none;
  /* Internet Explorer ve Edge için */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari ve Edge için */
}

/* select option */

select {
  appearance: none;
  /* iOS ve diğer tarayıcılar için varsayılan stili kaldırır */
  -webkit-appearance: none;
  /* iOS için */
  -moz-appearance: none;
  /* Firefox için */
  background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
  /* Özel ok simgesi ekleyebilirsiniz */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}